import React, { useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import styles from './CardBanner.module.scss';
import { object, array } from 'prop-types';
// import Item from './Item/Item';
import { langPath } from '@helpers';
import LangContext from '@contexts';
import { Link } from 'gatsby';

const CardBanner = ({ primary, items }) => {
    const { title } = primary;
    // console.log("cardBanner", items)
    // console.log("cardBanner", title)
    const currentLang = useContext(LangContext);
    return (
        <section className={styles.CardBanner}>
            <div className={styles.cardContainer}>
                <div className={styles.title}>
                    <RichText render={title.richText} />
                </div>
                {/* <div className={styles.list}>
                    {items.map((item) => {
                        return <Item {...item} key={item.link.url} />;
                    })}
                </div> */}
                <div className={styles.bannerData}>
                    {items?.map(({ title, description, screenshot, pagelink }, index) => {
                        const link = `${langPath(currentLang)}/${RichText.asText(
                            pagelink.richText
                        )}`;
                        return (
                            <Link to={link}>
                                <div className={styles.bannerContent} key={index}>
                                    <div className={styles.bannerImg}>
                                        <img src={screenshot?.url} alt={screenshot?.alt} />
                                    </div>
                                    <div className={styles.bannerText}>
                                        <RichText render={title?.richText} />
                                    </div>
                                    <div className={styles.bannerDesc}>
                                        <RichText render={description?.richText} />
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </section>
    );
};

CardBanner.propTypes = {
    primary: object,
    items: array,
};

export default CardBanner;
