import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

// import BlogPage from '@scenes/BlogPage';
import Layout from '@components/Layout';
import NewSolutionPage from '../scenes/NewSolutionPage';

const Page = ({ data }) => {
  const pageContext = data.allPrismicNewSolutionPage.edges[0];
  if (!pageContext) return null;
  const body = pageContext.node;
  const { data: pageData, uid, id, lang, type, alternate_languages } = body;
  const { metatitle, metadescription, canonical, body: pageContent } = pageData;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };
  const mainSection = data.allPrismicLayout.edges;

  // console.log('mainSection', mainSection);
  // console.log('activeDocMeta', activeDocMeta);
  // console.log("data", data)
  // console.log('pageContext', pageContext);

  return (
    <Layout
      activeDocMeta={activeDocMeta}
      metatitle={metatitle}
      metadescription={metadescription}
      canonical={canonical}
    >
      <NewSolutionPage
        current={pageContent}
        mainSection={mainSection}
        pageUid={uid}
        canonical={canonical}
        metatitle={metatitle}
        lang={lang}
      />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($uid: String, $lang: String) {
    allPrismicNewSolutionPage(filter: { uid: { eq: $uid }, lang: { eq: $lang } }) {
      edges {
        node {
          uid
          type
          lang
          id
          alternate_languages {
            id
            lang
            uid
            type
          }
          data {
            metatitle {
              text
            }
            metadescription {
              text
            }
            canonical {
              text
            }
            body {
              ... on PrismicNewSolutionPageDataBodyBenefits {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttonlink {
                    text
                  }
                  button {
                    text
                  }
                }
                items {
                  text {
                    richText
                  }
                  image {
                    alt
                    url
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyBooking {
                id
                slice_type
                primary {
                  title {
                    richText
                    text
                  }
                  link {
                    url
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyFeatures {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttonlink {
                    text
                  }
                  button {
                    text
                  }
                }
                items {
                  title {
                    richText
                  }
                  image {
                    alt
                    url
                  }
                  description {
                    richText
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyHero {
                id
                slice_type
                primary {
                  videobuttontext {
                    text
                  }
                  title {
                    richText
                  }
                  previewimage {
                    alt
                    url
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                  }
                  modalvideo {
                    link_type
                    url
                  }
                  modalctabuttontext {
                    text
                  }
                  modalctabuttonlink {
                    text
                  }
                  description {
                    text
                  }
                  buttontext {
                    text
                  }
                  buttonlink {
                    text
                  }
                  secondbuttonlink {
                    text
                  }
                  secondbuttontext {
                    text
                  }
                }
                items {
                  partnerslogo {
                    alt
                    url
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyPlans {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyProjects {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                }
                items {
                  title {
                    richText
                  }
                  screenshot {
                    alt
                    url
                  }
                  link {
                    link_type
                    url
                  }
                  description {
                    richText
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyCardBanner {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                }
                items {
                  title {
                    richText
                  }
                  screenshot {
                    alt
                    url
                  }
                  description {
                    richText
                  }
                  pagelink{
                    richText
                  }  
                }
              }
              ... on PrismicNewSolutionPageDataBodyCookiesteps {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttonlink {
                    text
                  }
                  button {
                    text
                  }
                }
                items {
                  mainimg {
                    alt
                    url
                  }
                  icon {
                    alt
                    url
                  }
                  description {
                    richText
                  }
                  title {
                    richText
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyRelatedblog{
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  buttonlink {
                    url
                  }
                }
                items {
                  title {
                    richText
                  }
                  description {
                    richText
                  }
                  tag {
                    richText
                  }
                  blogimg {
                    alt
                    url
                  }
                  bloglink {
                    url
                  }
                  date
                }
              }
              ... on PrismicNewSolutionPageDataBodyQuestions {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                }
                items {
                  linktext {
                    text
                  }
                  link {
                    link_type
                    url
                  }
                  content {
                    richText
                  }
                  title {
                    richText
                  }
                }
              }
              ... on PrismicNewSolutionPageDataBodyContent {
                id
                slice_type
                items {
                  title {
                    richText
                    text
                  }
                  content {
                    richText
                  }
                  shorttitle {
                    text
                  }
                }
                primary {
                  toctitle {
                    text
                    richText
                  }
                  maintitle {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicLayout {
      edges {
        node {
          data {
            body2 {
              ... on PrismicLayoutDataBody2Agencies {
                id
                slice_type
                primary {
                  title {
                    text
                  }
                  page {
                    text
                  }
                  link {
                    link_type
                    url
                  }
                  image {
                    alt
                    url
                  }
                  description {
                    richText
                  }
                  buttontext {
                    text
                  }
                  linktext {
                    text
                  }
                }
              }
              ... on PrismicLayoutDataBody2Plans {
                id
                slice_type
                items {
                  type
                  image {
                    alt
                    url
                  }
                  description {
                    richText
                  }
                  cardtitle {
                    richText
                  }
                  buttonprice {
                    richText
                  }
                  buttonlink {
                    link_type
                    url
                  }
                  button {
                    richText
                  }
                  benefits {
                    richText
                  }
                }
              }
            }
            language {
              text
            }
          }
        }
      }
    }
  }
`;

export default Page;
