import React from 'react';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';
import Button, { VARIANT } from '@components/Button/Button.js';
import styles from './CookieSteps.module.scss';

const CookieSteps = ({ primary, items }) => {
    const { title, buttonlink, button } = primary;
    // console.log("cookieSteps", items)
    // console.log("cookieSteps", primary)
    return (
        <section className={styles.cookieSteps}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <RichText render={title.richText} />
                </div>
                <div className={styles.list}>
                    {items.map((item) => {
                        return (
                            <>
                                <div className={styles.item}>
                                    <div className={styles.imageWrapper}>
                                        <div className={styles.content}>
                                            <RichText render={item.title.richText} />
                                            <RichText render={item.description.richText} />
                                        </div>
                                        <img src={item.icon.url} alt={item.icon.alt} />
                                    </div>
                                    <div className={styles.imagesMain}>
                                        <img src={item.mainimg.url} alt={item.mainimg.alt} />
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
                <div className={styles.button}>
                    <Button variant={VARIANT.ALLWHITE} to={buttonlink.text}>
                        <span>{button.text}</span>
                    </Button>
                </div>
            </div>
        </section>
    );
};

CookieSteps.propTypes = {
    primary: object,
    items: array,
};
export default CookieSteps;
