import React, { useState } from 'react';
import { object } from 'prop-types';

import { dateToString, parseString } from '@helpers';
// import LangContext from '@contexts';
// import style from './ArticlePreview.module.scss';
// import Arrow from './image/arrow.inline.svg';
import { Link } from 'gatsby';
import Button, { VARIANT } from '@components/Button/Button.js';
import styles from "./RelatedBlog.module.scss";
import { RichText } from 'prismic-reactjs';
import Arrow from './image/arrow.inline.svg';

const RelatedBlog = ({ primary, items }) => {
    const [counter, setCounter] = useState(3);
    // const { data: postData, tags, uid: postPath } = node;
    // const { title, description, date, preview } = postData;
    // const currentLang = useContext(LangContext);
    // const link = `${langPath(currentLang)}/blog/${postPath}`;
    const { title, buttonlink, buttontext } = primary;
    // const { blogimg, description, title, tag, date } = items;
    // console.log("relatedBlog", items)
    // console.log("relatedBlog", primary)

    const handleClickLoadMore = () => {
        setCounter(counter + 3);
    };

    // console.log(counter, "counter")

    return (
        // <Link to={""} className={"style.preview"}>
        <section className={styles.relatedBlog}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <RichText render={title.richText} />
                </div>
                <div className={styles.blogPreview}>
                    {items?.slice(0, counter)?.map((item, index) => (
                        <>
                            <Link to={item?.bloglink?.url}>
                                <div key={index} className={styles.allArticle}>
                                    <div className={styles.imagePreview} >
                                        {item?.blogimg ? <img src={item?.blogimg?.url} alt={item?.blogimg?.alt} /> : ("")}
                                    </div>
                                    <div className={styles.textBlock}>
                                        <h3 className={styles.titleArticle}>{parseString(item?.title?.richText)}</h3>
                                        <p className={styles.text}>{parseString(item?.description?.richText)}</p>
                                        <div className={styles.wrapper}>
                                            {item?.tag?.richText?.length > 0 ?
                                                <div className={styles.tags}>
                                                    <RichText render={item?.tag?.richText} />
                                                </div> : <div></div>
                                            }
                                            <time className={styles.date}>{dateToString(item?.date)}</time>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.arrow}>
                                    <Arrow />
                                </div>
                            </Link>
                        </>
                    ))}
                </div>
                {/* {items?.length > 3 ? <div className={styles.articleButton}>
                    <RichText render={buttontext?.richText} />
                </div> : ("")} */}
                <div className={styles.articleButton}>
                    {items?.length > counter && (
                        <Button
                            variant={VARIANT.SECONDARY}
                            click={handleClickLoadMore}
                            element="button"
                        >
                            <RichText render={buttontext?.richText} />
                        </Button>
                    )}
                </div>
            </div>
        </section>
        // </Link>
    );
};

RelatedBlog.propTypes = {
    node: object,
};

export default RelatedBlog;
