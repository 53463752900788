import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './NewSolutionPage.module.scss';
import 'swiper/swiper.scss';
import NewHero from '../../components/NewHero';
import CardBanner from './components/CardBanner/CardBanner';
import CookieSteps from './components/CookieSteps/CookieSteps';
import SolutionQuestions from './components/SolutionQuestions/SolutionQuestions';
import RelatedBlog from './components/RelatedBlog/RelatedBlog';

// import Hero from '@components/Hero';
// import Projects from './components/Projects/Projects';
// import Benefits from './components/Benefits/Benefits';
// import Features from './components/Features/Features';
// import Questions from '@components/Questions/Questions';
// import Agencies from '@components/Agencies';
// import Plans from '@components/Plans';
// import Calendly from '@components/Calendly/Calendly';
// import FaqSemanticMarkup from '@components/FaqSemanticMarkup/FaqSemanticMarkup';
// import BreadcrumbsSemanticMarkup from '@components/BreadcrumbsMarkup/BreadcrumbsMarkup';
// import Content from '@components/Content/Content';

const NewSolutionPage = ({
    current: body,
    mainSection,
    pageUid,
    canonical,
    metatitle,
    lang: pageLang,
}) => {
    const mainSectionLang = mainSection.filter(
        ({ node }) => node.data.language.text === pageLang
    );

    const agenciesSection = mainSectionLang[0].node.data.body2[0];
    const plansSection = mainSectionLang[0].node.data.body2[1];

    const hospitalityPageUid = 'hospitality';
    const questions = body.filter((item) => item.slice_type === 'questions');
    const contentQuestions = body.filter((item) => item.slice_type === 'content');
    const questionsSliceFaqLists = questions.map((element) => element.items);
    const contentSliceFaqLists = contentQuestions.map((element) => element.items);
    const questionsSliceFaqList = questionsSliceFaqLists.flat();
    const contentSliceFaqList = contentSliceFaqLists.flat();
    const generalFaqList = [...questionsSliceFaqList, ...contentSliceFaqList];

    // console.log('body', body);

    const handleMoveToWhatYouNeed = () => {
        const divElement = document.getElementById('what_you_need_make');
        divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className={style.SolutionPage}>
            {body.map((section, index) => {
                switch (section.slice_type) {
                    case 'hero':
                        return (
                            <NewHero
                                {...section}
                                key={`${section.slice_type}${index}`}
                                handleMoveToWhatYouNeed={handleMoveToWhatYouNeed}
                            />
                        );
                    case 'card_banner':
                        return (
                            <CardBanner {...section} key={`${section.slice_type}${index}`} />
                        )
                    case 'cookiesteps':
                        return (
                            <CookieSteps {...section} key={`${section.slice_type}${index}`} />
                        );
                    case 'questions':
                        return (
                            <SolutionQuestions {...section} key={`${section.slice_type}${index}`} />
                        );
                    case 'relatedblog':
                        return (
                            <RelatedBlog {...section} key={`${section.slice_type}${index}`} />
                        );
                    // case 'projects':
                    //     return (
                    //         <Projects {...section} key={`${section.slice_type}${index}`} />
                    //     );
                    // case 'benefits':
                    //     return (
                    //         <Benefits {...section} key={`${section.slice_type}${index}`} />
                    //     );
                    // case 'features':
                    //     return (
                    //         <div id="what_you_need_make">
                    //             <Features {...section} key={`${section.slice_type}${index}`} />
                    //         </div>
                    //     );
                    // case 'questions':
                    //     return (
                    //         <Questions {...section} key={`${section.slice_type}${index}`} />
                    //     );
                    // case 'booking':
                    //     return (
                    //         <Calendly {...section} key={`${section.slice_type}${index}`} />
                    //     );
                    // case 'plans':
                    //     return (
                    //         <Plans
                    //             {...section}
                    //             {...plansSection}
                    //             key={`${section.type}${index}`}
                    //         />
                    //     );
                    // case 'content':
                    //     return <Content {...section} key={`${section.type}${index}`} />;
                    default:
                        throw new Error(`Unknown section type: ${section.slice_type}`);
                }
            })}
            {/* {pageUid !== hospitalityPageUid && <Agencies {...agenciesSection} />} */}
            {/* <FaqSemanticMarkup questions={generalFaqList} />
            <BreadcrumbsSemanticMarkup
                pageTitle={metatitle.text}
                pageUrl={canonical.text}
            /> */}
        </div>
    );
};

NewSolutionPage.propTypes = {
    current: PropTypes.array.isRequired,
    mainSection: PropTypes.array,
    pageUid: PropTypes.string,
    canonical: PropTypes.object.isRequired,
    metatitle: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

export default NewSolutionPage;
